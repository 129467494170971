import React, { useCallback, useMemo } from 'react'
import { Link as GatsbyLink, useI18next } from 'gatsby-plugin-react-i18next'

export const useNavigation = () => {
  const { navigate } = useI18next()

  return navigate
}

const Link = ({ to: _to, children, onClick: _onClick, ...props }) => {
  const { to, href, type, onClick } = useMemo(() => {
    if (!_to || typeof _to === 'string') {
      return {
        type: 'internal',
        to: _to,
        onClick: undefined,
      }
    }

    return _to
  }, [_to])

  const handleClick = useCallback(
    (...args) => {
      if (onClick) {
        onClick(...args)
      }

      if (_onClick) {
        _onClick(...args)
      }
    },
    [onClick, _onClick]
  )

  const toWithSlash = useMemo(() => {
    if (!to) {
      return undefined
    }

    if (to.slice(-1) === '/') {
      return to
    }

    return `${to}/`
  }, [to])

  if (type === 'external') {
    return (
      <a href={href} onClick={handleClick} {...props}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink to={toWithSlash} onClick={handleClick} {...props}>
      {children}
    </GatsbyLink>
  )
}
export default Link
