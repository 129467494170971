import React, { useMemo, useState } from 'react'
import ThemeProvider from 'ui/elements/ThemeProvider'
import { JsonLd, JsonLdProps } from 'gatsby-plugin-next-seo'

import Header from './Header'
import Footer from './Footer'
import { LanguageSwitcherContextProvider } from 'contexts/LanguageSwitcherContext'
import { IconsContextProvider } from 'contexts/IconsContext'
import { graphql, PageProps } from 'gatsby'
import InfoBar from './InfoBar/InfoBar'
import HeaderCTA from 'components/Layout/HeaderCTA/HeaderCTA'

const structuredPageData: JsonLdProps<any>['json'] = {
  '@context': 'http://schema.org/',
  '@type': 'Organization',
  name: 'WHU - Otto Beisheim School of Management – Executive Education',
  alternateName: 'WHU - Otto Beisheim School of Management',
  url: 'https://ee.whu.edu/',
  logo: 'https://ee.whu.edu/logo.svg',
  vatId: 'DE148733174',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+4926165090',
    email: 'whu@whu.edu',
    faxNumber: '+492616509509',
  },
  location: {
    '@type': 'Place',
    hasMap:
      'https://www.google.com/maps/place/WHU+-+Otto+Beisheim+School+of+Management/@50.4003254,7.6113179,17z/data=!3m1!4b1!4m5!3m4!1s0x47be7d3cc638671b:0x73da18b40028eefb!8m2!3d50.4003254!4d7.6135066',
  },
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'DE',
    addressRegion: 'Rheinland-Pfalz',
    addressLocality: 'Vallendar',
    streetAddress: 'Burgpl. 2',
    postalCode: '56179',
  },
}

export const query = graphql`
  fragment PageLayoutConfig on Query {
    ...HeaderCTAConfig
  }
`

const PageLayout: React.FC<PageProps<any, any>> = ({
  children,
  data,
  pageContext,
}) => {
  const hasNavigation = !pageContext.landingPage
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const showCta = useMemo(() => {
    return !data?.sanityLandingPage
  }, [data])

  return (
    <ThemeProvider>
      <LanguageSwitcherContextProvider>
        <IconsContextProvider>
          <JsonLd json={structuredPageData} />
          <InfoBar
            config={data?.infoBarConfig ?? undefined}
            hideMobile={menuOpen}
          />
          <Header onMenuOpen={setMenuOpen} hasNavigation={hasNavigation} />
          {showCta && (
            <HeaderCTA config={data?.headerCTASiteConfig ?? undefined} />
          )}
          {children}
          <Footer />
        </IconsContextProvider>
      </LanguageSwitcherContextProvider>
    </ThemeProvider>
  )
}

export default PageLayout
