import { css } from 'styled-components'

const inputs = {
  padding: {
    vertical: 12,
    horizontal: 18,
  },
  fontSize: 1.4,
  display: (fill?: boolean) => (fill ? 'flex' : 'inline-flex'),
}

const grid = {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1170,
  },
  containerWidths: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1170,
  },
  gutterWidth: 30,
}

const global = {
  lineHeight: 1 + 1 / 3,
  fontSize: '1.8rem',
  fontFamily: ['Helvetica Neue', 'Arial Regular', 'sans-serif'].join(', '),
}

export type BreakpointLabel = keyof typeof grid['breakpoints']
type BreakpointLabelMax = `max_${BreakpointLabel}`

type CssFunction = (...args: Parameters<typeof css>) => ReturnType<typeof css>
type BreakpointCollection = Record<
  BreakpointLabel | BreakpointLabelMax,
  CssFunction
>

export const breakpoint = (
  Object.keys(grid.breakpoints) as BreakpointLabel[]
).reduce<Partial<BreakpointCollection>>((accumulator, label) => {
  accumulator[label] = (...args: Parameters<typeof css>) => css`
    @media (min-width: ${grid.breakpoints[label]}px) {
      ${css(...args)};
    }
  `

  accumulator[`max_${label}` as BreakpointLabelMax] = (
    ...args: Parameters<typeof css>
  ) => css`
    @media (max-width: ${grid.breakpoints[label] - 1}px) {
      ${css(...args)};
    }
  `

  return accumulator
}, {}) as BreakpointCollection

export default {
  inputs,
  global,
  grid,
}
