import React from 'react'
import { Row, Col } from 'react-grid-system'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Link from 'widgets/Link'
import Button from 'ui/elements/Button'
import { Heading } from 'ui/elements/Heading/styles'
import OpticalHeading from 'ui/elements/Heading/OpticalHeading'
import TextInput from 'ui/elements/TextInput'

const S = {}
S.Newsletter = styled.div``

S.Header = styled.header`
  padding-right: 30%;
  margin-bottom: 20px;

  ${Heading} {
    margin-bottom: 15px;

    &:last-child {
      margin: 0;
    }
  }
`

S.Footer = styled.footer`
  color: rgb(161, 161, 161);
  font-size: 1.2rem;

  a {
    color: ${(props) => props.theme.palette.white};
  }

  p {
    margin-bottom: 0;
  }
`

const Newsletter = () => {
  const { t } = useTranslation('footer')
  return (
    <S.Newsletter>
      <S.Header>
        <OpticalHeading level={3}>{t('newsletter.title')}</OpticalHeading>
        <OpticalHeading level={6}>{t('newsletter.subtitle')}</OpticalHeading>
      </S.Header>
      <form
        action="https://go.pardot.com/l/877492/2020-09-11/4b3xk"
        method="post"
      >
        <Row gutterWidth={10}>
          <Col xs={12} md={8}>
            <TextInput
              fill
              placeholder={t('newsletter.email')}
              color="shade"
              name="email"
            />
          </Col>
          <Col xs={12} md={4}>
            <Button
              as="button"
              type="submit"
              color="shade"
              label={t('newsletter.button')}
              fill
            />
          </Col>
        </Row>
      </form>
      <S.Footer>
        <Link to="/privacy-statement">{t('newsletter.disclaimer')}</Link>
      </S.Footer>
    </S.Newsletter>
  )
}

export default Newsletter
