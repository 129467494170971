import React, { useEffect, useMemo, useState } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useLocation, globalHistory } from '@reach/router'
import { useI18next } from 'gatsby-plugin-react-i18next'

/**
 * @type {React.Context<{
 *  setForceTranslatedUrls: function,
 *  forceTranslatedUrls: object | undefined
 * }>}
 */
export const LanguageSwitcherContext = React.createContext({
  forceTranslatedUrls: undefined,
  setForceTranslatedUrls: () => {},
})

export const LanguageSwitcherContextProvider = ({ children }) => {
  const [forceTranslatedUrls, setForceTranslatedUrls] = useState()
  const location = useLocation()
  const { language } = useI18next()

  const ctx = {
    forceTranslatedUrls,
    setForceTranslatedUrls,
  }

  useEffect(() => {
    // clear alternatives on page change
    return globalHistory.listen(() => {
      setForceTranslatedUrls(undefined)
    })
  }, [])

  const alternateLinks = useMemo(() => {
    const alternatives = []

    for (const lang in forceTranslatedUrls) {
      if (!forceTranslatedUrls.hasOwnProperty(lang) || language === lang) {
        continue
      }

      alternatives.push({
        type: 'alternate',
        hreflang: lang,
        href: `${location.origin}${forceTranslatedUrls[lang]}`,
      })
    }

    return alternatives
  }, [location, language, forceTranslatedUrls])

  return (
    <LanguageSwitcherContext.Provider value={ctx}>
      <GatsbySeo linkTags={alternateLinks} />
      {children}
    </LanguageSwitcherContext.Provider>
  )
}
